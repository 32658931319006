import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { concat, forkJoin, Observable, of, Subject } from "rxjs";
import {
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { ChildAuthService } from "src/app/services/auth/child-auth.service";
import { GetprojectsService } from "src/app/services/getprojects/getprojects.service";
import {
  GetusersNotInAProjectService,
  Person,
} from "src/app/services/getusersnotinaprojectservice/getusers-not-in-a-project.service";
import { InviteService } from "src/app/services/invite-service/invite.service";
import { ProjectCreateService } from "src/app/services/projectcreate/project-create-service";
import { ROLES } from "../../constants/userRoles";
@Component({
  selector: "app-manage-users",
  templateUrl: "./project-manage-users.component.html",
  styleUrls: ["./project-manage-users.component.css"],
})
export class ProjectManageUsers implements OnInit {
  projectDetails: any = {};
  projectUid: String;
  projectUsers: any = [];
  loadingText: String;
  userRoles: any;
  userCnt = 1;
  users = [
    {
      email: null,
      role: ROLES.PROJECT_ADMIN,
    },
  ];
  isUserInProject: any = {};
  isAdmin: Boolean;
  dtTrigger: Subject<any> = new Subject<any>();

  people$: Observable<Person[]>;
  peopleLoading = false;
  peopleInput$ = new Subject<string>();
  selectedPerson: Person = null;
  loggedInUsername: String;
  editedRole: String;
  // check if all the entered emails are valid

  private refreshUsers(toasterSuccessMessage) {
    this.getProjectService
      .getProjectUsers(this.projectUid)
      .subscribe((data) => {
        this.projectUsers = data;
        this.toastr.success(toasterSuccessMessage);
        this.spinner.hide();
        this.ngxSmartModalService.getModal("deleteUser").close();
      });
  }

  isAllEmailsValid() {
    return this.users.every(
      (e) => e.email && this.validateEmail(e.email.username)
    );
  }

  // open the resend invite modal
  openResendInviteModal(email, uid) {
    this.ngxSmartModalService.getModal("resendInvite").open();
    this.ngxSmartModalService.setModalData(
      {
        email,
        uid,
      },
      "resendInvite"
    );
  }

  // make api call to resend invite
  resendProjectInvite(inviteUId) {
    this.spinner.show();
    this.loadingText = "Resending Invite ...";
    this.inviteService.resendInvite(inviteUId).subscribe((data) => {
      this.spinner.hide();
      this.ngxSmartModalService.getModal("resendInvite").close();
      this.toastr.success("Invite resent successfully");
    });
  }

  // open confirmation modal for delete user
  deleteUserFromProjectConfirmation(name, uid) {
    this.ngxSmartModalService.getModal("deleteUser").open();
    this.ngxSmartModalService.setModalData(
      {
        name,
        uid,
      },
      "deleteUser"
    );
  }

  // init api call to delete user from project
  deleteUserFromProject(userUid) {
    this.loadingText = "Removing user...";
    this.spinner.show();
    const formData = {
      userId: userUid,
      projectId: this.projectUid,
    };
    this.projectService.deleteUserFromProject(formData).subscribe((_) => {
      this.refreshUsers("User removed successfully");
    });
  }

  // open model on delete invite
  openDeleteInviteModal(email, inviteUid) {
    this.ngxSmartModalService.getModal("deleteInvite").open();
    this.ngxSmartModalService.setModalData(
      {
        uid: inviteUid,
        email,
      },
      "deleteInvite"
    );
  }

  // open edit user modal
  openEditUserModal(email, role, uid) {
    this.ngxSmartModalService.getModal("editUser").open();
    this.editedRole = role;
    this.ngxSmartModalService.setModalData(
      {
        email,
        role,
        uid,
      },
      "editUser"
    );
  }

  // make api call to persist changes to db
  saveEditUserRole(userUid) {
    this.loadingText = "Changing user role ...";
    this.spinner.show();
    this.projectService
      .editUserRoleInProject({
        userId: userUid,
        projectId: this.projectUid,
        role: this.editedRole,
      })
      .subscribe((_) => {
        this.refreshUsers("User role changed successfully");
      });
  }

  // API call to delete project invite
  deleteProjectInvite(inviteUid) {
    this.loadingText = "Deleting invite...";
    this.spinner.show();
    this.inviteService.deleteInvite(inviteUid).subscribe((data) => {
      this.refreshUsers("Invite deleted successfully");
    });
  }

  // load existing users for typeahead
  private loadPeople() {
    this.people$ = concat(
      of([]), // default items
      this.peopleInput$.pipe(
        distinctUntilChanged(),
        tap(() => (this.peopleLoading = true)),
        switchMap((term) =>
          this.getUserNotInaProject.getPeople(term, this.projectUid).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.peopleLoading = false))
          )
        )
      )
    );
  }

  trackByFn(item: Person) {
    return item.uid;
  }

  // remove user
  removeUser() {
    this.userCnt--;
    this.users.pop();
  }

  // add user
  addAnotherUser() {
    this.userCnt++;
    this.users.push({
      email: null,
      role: ROLES.PROJECT_ADMIN,
    });
  }

  // check if user already exists in project, used to display alert messages and disable btn
  checkIfUserAlreadyExists(email) {
    return this.projectUsers.findIndex((e) => e.user.username === email) !== -1;
  }

  // check if any user already exists in the project and disable the save btn
  checkIfAnyUserAlreadyExists() {
    const projectUsers = this.projectUsers.map((e) => e.user.username);
    for (const user of this.users) {
      if (projectUsers.includes(user.email.username)) {
        return true;
      }
    }

    return false;
  }

  // regex for email validation
  validateEmail(email) {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  constructor(
    private getProjectService: GetprojectsService,
    private route: ActivatedRoute,
    private getUserNotInaProject: GetusersNotInAProjectService,
    private router: Router,
    private inviteService: InviteService,
    authService: ChildAuthService,
    private spinner: NgxSpinnerService,
    private projectService: ProjectCreateService,
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService
  ) {
    this.isAdmin = authService.loggedInDetails.isAdmin;
    this.loggedInUsername = authService.loggedInDetails.username;
  }

  // save the entered user details
  saveUsers() {
    this.loadingText = "Adding users...";
    this.spinner.show();
    const formData = {
      users: this.users.map((e) => {
        return {
          email: e.email.username,
          role: e.role,
        };
      }),
    };
    this.projectService
      .addUsersToAProject(formData, this.projectUid)
      .subscribe((_) => {
        this.ngxSmartModalService.getModal("addUser").close();
        this.refreshUsers("Users added successfully");
      });
  }

  // init modal variables
  openAddUserModal() {
    this.userCnt = 1;
    this.users = [
      {
        email: null,
        role: ROLES.PROJECT_ADMIN,
      },
    ];
    this.ngxSmartModalService.getModal("addUser").open();
  }

  // fetch project details and users in the project on page load
  ngOnInit(): void {
    this.userRoles = ROLES;
    this.loadPeople();
    this.loadingText = "Fetching Details...";
    this.spinner.show();
    this.projectUid = this.route.snapshot.paramMap.get("projectUid");
    forkJoin(
      this.getProjectService.getProject(this.projectUid),
      this.getProjectService.isUserAProjectAdmin(this.projectUid),
      this.getProjectService.getProjectUsers(this.projectUid)
    ).subscribe(([projectData, isUserAProjectAdmin, projectUsers]) => {
      this.projectDetails = projectData;
      this.isUserInProject = isUserAProjectAdmin;
      this.projectUsers = projectUsers;
      this.dtTrigger.next();
      this.spinner.hide();
    });
  }
}
