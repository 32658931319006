import { Component, OnInit } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { concat, forkJoin, Observable, of, Subject } from "rxjs";
import {
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { UserService } from "src/app/services/admin/user.service";
import { ChildAuthService } from "src/app/services/auth/child-auth.service";
import { InviteService } from "src/app/services/invite-service/invite.service";
import {
  LoadAdminService,
  Person,
} from "src/app/services/loadadmin/loadadmin.service";
import { ProjectCreateService } from "src/app/services/projectcreate/project-create-service";

@Component({
  selector: "app-manageusers",
  templateUrl: "./manageusers.component.html",
  styleUrls: ["./manageusers.component.css"],
})
export class ManageusersComponent implements OnInit {
  isUserAdmin: boolean;
  userKeys: any;
  users: any;
  dtTrigger: Subject<any> = new Subject<any>();
  loadingText: String;
  loggedInEmail: String;
  people$: Observable<Person[]>;
  peopleLoading = false;
  peopleInput$ = new Subject<string>();
  selectedPersons: Person[] = [];
  pendingInvites = [];
  projectMappings = [];

  constructor(
    authService: ChildAuthService,
    public ngxSmartModalService: NgxSmartModalService,
    public projectService: ProjectCreateService,
    private loadAdminService: LoadAdminService,
    private inviteService: InviteService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private userService: UserService
  ) {
    this.isUserAdmin = authService.loggedInDetails.isAdmin;
    this.loggedInEmail = authService.loggedInDetails.username;
    this.loadPeople();
  }

  private loadPeople() {
    this.people$ = concat(
      of([]), // default items
      this.peopleInput$.pipe(
        distinctUntilChanged(),
        tap(() => (this.peopleLoading = true)),
        switchMap((term) =>
          this.loadAdminService.getPeople(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.peopleLoading = false))
          )
        )
      )
    );
  }

  addAdmins() {
    this.loadingText = "Adding Admins ...";
    this.spinner.show();
    this.userService.addAdmins(this.selectedPersons).subscribe((_) => {
      this.ngxSmartModalService.getModal("addAdmin").close();
      this.fetchAllUsers("Added Admins");
    });
  }

  trackByFn(item: Person) {
    return item.uid;
  }

  // see if the emails entered are valid
  isValidEmails(selectedPersons) {
    return selectedPersons.every((person) =>
      this.validateEmail(person.username)
    );
  }

  // check with email regex.
  validateEmail(email) {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // open view project modal
  // if there are a lot of projects displaying it in the table becomes cluttered
  // hence open modal
  openViewProjectsModal(projectMappings, name, userUid) {
    this.ngxSmartModalService.getModal("viewProjects").open();
    this.projectMappings = projectMappings;
    this.ngxSmartModalService.setModalData(
      {
        name,
        userUid,
      },
      "viewProjects",
      true
    );
  }

  // open modal for deleting user
  openDeleteUserModal(email) {
    this.ngxSmartModalService.getModal("deleteUser").open();
    this.ngxSmartModalService.setModalData(
      {
        email,
      },
      "deleteUser",
      true
    );
  }

  // make API call to delete user
  userDelete(email) {
    this.loadingText = "Deleting user...";
    this.spinner.show();
    this.userService.deleteUser(email).subscribe((_) => {
      this.ngxSmartModalService.getModal("deleteUser").close();
      this.fetchAllUsers("Deleted user");
    });
  }

  // fetch all users in the app
  fetchAllUsers(toastMessage) {
    forkJoin(
      this.userService.getAllUsers(),
      this.userService.getPendingAdminInvites()
    ).subscribe(([getAllUsers, pendingInvites]) => {
      this.userKeys = Object.keys(getAllUsers);
      this.users = getAllUsers;
      this.pendingInvites = pendingInvites;
      this.spinner.hide();
      this.toastr.success(toastMessage);
    });
  }

  // assign Admin role to the user
  assignAdminRole(userEmail) {
    this.spinner.show();
    this.loadingText = "Assigning admin role ...";
    this.userService.assignAdminRole(userEmail).subscribe((_) => {
      this.fetchAllUsers("Assigned admin role");
    });
  }

  removeAdminRole(userEmail) {
    this.spinner.show();
    this.loadingText = "Removing admin role ...";
    this.userService.removeAdminRole(userEmail).subscribe((_) => {
      this.fetchAllUsers("Removed admin role");
    });
  }

  openDeleteAdminInviteModal(uid, email) {
    this.ngxSmartModalService.setModalData(
      {
        email,
        uid,
      },
      "deleteInvite",
      true
    );
    this.ngxSmartModalService.getModal("deleteInvite").open();
  }

  // resend admin invite
  resendAdminInvite(inviteUid) {
    this.loadingText = "Resending Invite ...";
    this.spinner.show();
    this.inviteService.resendInvite(inviteUid).subscribe((_) => {
      this.fetchAllUsers("Resent invite");
    });
  }

  // delete admin invite
  deleteAdminInvite(inviteUid) {
    this.loadingText = "Deleting Invite ...";
    this.spinner.show();
    this.inviteService.deleteInvite(inviteUid).subscribe((_) => {
      this.ngxSmartModalService.getModal("deleteInvite").close();
      this.fetchAllUsers("Deleted invite");
    });
  }

  // delete user from project
  // use existing service from project manage users, yay
  removeUserFromProject(userUid, projectUid, userName) {
    const formData = {
      userId: userUid,
      projectId: projectUid,
    };
    this.loadingText = "Deleting User ...";
    this.spinner.show();
    this.projectService.deleteUserFromProject(formData).subscribe((_) => {
      const { projectMappings } = this.ngxSmartModalService
        .getModal("viewProjects")
        .getData();
      projectMappings.splice(
        projectMappings.findIndex((e) => e.projectUid === projectUid),
        1
      );
      if (projectMappings.length === 0) {
        // close the modal
        this.ngxSmartModalService.getModal("viewProjects").close();
      } else {
        this.ngxSmartModalService.setModalData(
          {
            projectMappings,
            name: userName,
            userUid,
          },
          "viewProjects",
          true
        );
      }
      this.fetchAllUsers("Deleted user from project");
    });
  }

  // save all the changes in the modal
  saveProjectRoleChanges(userUid) {
    this.loadingText = "Assigning Roles...";
    this.spinner.show();
    const tasks = [];

    // create an array of observables
    this.projectMappings.forEach((e) => {
      tasks.push(
        this.projectService.editUserRoleInProject({
          userId: userUid,
          projectId: e.projectUid,
          role: e.userRole,
        })
      );
    });

    // spread it out and forkjoin
    forkJoin(...tasks).subscribe((_) => {
      this.ngxSmartModalService.getModal("viewProjects").close();
      this.fetchAllUsers("Saved changes");
    });
  }

  ngOnInit(): void {
    this.loadingText = "Loading...";
    this.spinner.show();
    forkJoin(
      this.userService.getAllUsers(),
      this.userService.getPendingAdminInvites()
    ).subscribe(([users, pendingInvites]) => {
      this.userKeys = Object.keys(users);
      this.users = users;
      this.pendingInvites = pendingInvites;
      this.dtTrigger.next();
      this.spinner.hide();
    });
  }
}
