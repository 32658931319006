import { Component, OnInit } from "@angular/core";
import { ActivationEnd, Router } from "@angular/router";
import { ChildAuthService } from "./services/auth/child-auth.service";

//Sample comment to test CI integration, if you can see this delete me
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "app";
  username: any;
  //List of URLs to hide the top bar on
  headerExcludedRoutes = [
    "app/loginoauth",
    "app/inviteValidate",
    "app/:projectUid/dashboard",
    "app/:projectUid/digitspan",
    "app/:projectUid/digitspanrunning",
    "app/:projectUid/locationspan",
    "app/:projectUid/locationspanrunning",
    "app/:projectUid/visualspan",
    "app/:projectUid/visualspanrunning",
    "app/:projectUid/visualbinding",
    "app/:projectUid/crossmodalbinding",
    "app/:projectUid/phonological",
    "app/:projectUid/numberupdatevisual",
    "app/:projectUid/numberupdateauditory",
    "app/:projectUid/nonword",
    "app/:projectUid/repetitionauditory",
    "app/:projectUid/repetitionvisual",
    "app/:projectUid/store",
  ];
  currentPath: any;

  constructor(private authService: ChildAuthService, private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof ActivationEnd) {
        this.currentPath = event.snapshot.routeConfig.path;
        if (this.authService.loggedInDetails) {
          this.username = this.authService.loggedInDetails.name;
        }
      }
    });
  }
}
