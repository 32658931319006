import { Component, OnInit } from "@angular/core";
import { games } from "src/app/constants/games";
import { ChildAuthService } from "src/app/services/auth/child-auth.service";
import { ProjectCreateService } from "src/app/services/projectcreate/project-create-service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { concat, Observable, of, Subject } from "rxjs";
import {
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import {
  LoadAdminService,
  Person,
} from "../../services/loadadmin/loadadmin.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-projectcreate",
  templateUrl: "./projectcreate.component.html",
  styleUrls: ["./projectcreate.component.css"],
})
export class ProjectcreateComponent {
  isUserAdmin: any;

  constructor(authService: ChildAuthService) {
    this.isUserAdmin = authService.loggedInDetails.isAdmin;
  }
}
