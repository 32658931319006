import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { endpoints } from "src/app/constants/endpoints";
import { ChildAuthService } from "src/app/services/auth/child-auth.service";
import { GetprojectsService } from "src/app/services/getprojects/getprojects.service";
import { ProjectCreateService } from "src/app/services/projectcreate/project-create-service";

import { ErrorService } from "../../services/errors/error.service";
import { GeneralHttpService } from "../../services/utils/general-http.service";

@Component({
  selector: "app-data-upload-redcap",
  templateUrl: "./data-upload-redcap.component.html",
  styleUrls: ["./data-upload-redcap.component.css"],
})
export class DataUploadRedcapComponent implements OnInit {
  projectUid: any;
  dataExportLocation: any;
  dataExportChoice: any = "EXPORT_LOCATION";

  selectRecords: any = [];
  loadingText: any;

  constructor(
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private httpService: GeneralHttpService,
    private projectService: GetprojectsService,
    private toastr: ToastrService,
    authService: ChildAuthService,
    private spinner: NgxSpinnerService,
    private getProjectService: GetprojectsService
  ) {
    this.isAdmin = authService.loggedInDetails.isAdmin;
  }

  ngOnInit() {
    this.projectUid = this.route.snapshot.paramMap.get("projectUid");
    forkJoin(
      this.projectService.getProject(this.projectUid),
      this.getProjectService.isUserAProjectAdmin(this.projectUid)
    ).subscribe(([d, isUserAProjectAdmin]) => {
      this.processingRowIndex = 0;
      this.fetchDataToBeProcessed();
      this.disableTestBtn = false;
      this.disableUploadBtn = false;

      // this.apiURL = "https://redcap.chs.asu.edu/api/";
      // this.apiToken = "62512698E1121E280309CBA55BE24194";
      // this.deviceId = "1";
      this.testMessage = undefined;
      this.connectionSuccess = undefined;
      if (d.dataHandling === "ALL") {
        this.dataExportLocation = "Dropbox";
      } else {
        this.dataExportLocation = d.dataExportLocation;
      }
      this.isUserInProject = isUserAProjectAdmin;
    });
  }

  // public variables
  apiURL: String;
  apiToken: String;
  deviceId: String;
  dataToBeProcessed;
  internalServerError: String;
  disableTestBtn;
  testMessage: String;
  connectionSuccess;
  disableUploadBtn;

  isUserInProject: any = {};
  isAdmin: Boolean;

  private processingRowIndex;

  public testConnection() {
    this.disableTestBtn = true;
    this.disableUploadBtn = true;
    const apiConfig = {
      token: this.apiToken,
      url: this.apiURL,
      deviceId: this.deviceId,
    };
    const formData = {
      apiConfig: apiConfig,
      projectUid: this.projectUid,
    };
    this.httpService.post("./api/redcap/testconnection", formData).subscribe(
      (data) => {
        if (data != undefined) {
          this.disableTestBtn = false;
          this.disableUploadBtn = false;
          if (
            data["syncDataResult"] != undefined &&
            data["syncDataResult"]["respCode"] == 200 &&
            data["syncDataResult"]["affectedRowsCount"] == 1
          ) {
            this.connectionSuccess = true;
            this.testMessage = "Connection Success!";
          } else if (data["syncDataResult"] != undefined) {
            this.connectionSuccess = false;
            if (data["syncDataResult"]["respCode"] == 200) {
              this.testMessage =
                "Connection Fail! Please check your URL and API token.";
            } else {
              this.testMessage = data["syncDataResult"]["errorDetail"];
            }
          }
        } else {
          this.errorService.internalError();
        }
      },
      (err) => {
        this.errorService.networkError();
      }
    );
  }

  // fn to call on export btn click
  exportDataToConfiguredLocation() {
    console.log(this.dataToBeProcessed);

    // if no selected records display err message
    if (this.selectRecords.filter((e) => e === true).length === 0) {
      this.toastr.error("Select the records to be exported");
      return;
    }

    // filter out the data
    this.dataToBeProcessed = this.dataToBeProcessed.filter(
      (e, index) => this.selectRecords[index] === true
    );
    this.loadingText = "Exporting Data ...";
    this.spinner.show();

    // export data
    this.httpService
      .post(
        `${endpoints.EXPORT_PROJECT_DATA}?projectUid=${this.projectUid}`,
        this.dataToBeProcessed.map((e) => {
          return {
            id: e.childId,
            gameId: e.gameId,
            grade: e.grade,
            projectUid: this.projectUid,
          };
        })
      )
      .subscribe((d) => {
        this.spinner.hide();
        this.toastr.success("Project Data exported successfully");
      });
  }

  uploadData() {
    this.disableUploadBtn = true;
    if (this.processingRowIndex >= this.dataToBeProcessed.length) {
      this.disableUploadBtn = false;
      return;
    }
    if (
      this.dataToBeProcessed[this.processingRowIndex].totalRecordsCount == 0 ||
      this.dataToBeProcessed[this.processingRowIndex].syncStatus === "Finished"
    ) {
      if (
        this.dataToBeProcessed[this.processingRowIndex].totalRecordsCount == 0
      ) {
        this.dataToBeProcessed[this.processingRowIndex].syncStatus = "NoData";
      }
      this.processingRowIndex++;
      this.uploadData();
    } else {
      this.dataToBeProcessed[this.processingRowIndex].syncStatus = "Processing";
      const apiConfig = {
        token: this.apiToken,
        url: this.apiURL,
        deviceId: this.deviceId,
      };
      const formData = {
        childId: this.dataToBeProcessed[this.processingRowIndex].childId,
        grade: this.dataToBeProcessed[this.processingRowIndex].grade,
        gameId: this.dataToBeProcessed[this.processingRowIndex].gameId,
        category: this.dataToBeProcessed[this.processingRowIndex].category,
        cohort: this.dataToBeProcessed[this.processingRowIndex].cohort,
        apiConfig: apiConfig,
        projectUid: this.projectUid,
      };
      this.httpService.post("./api/redcap/processing", formData).subscribe(
        (data) => {
          if (data != undefined) {
            if (
              data["syncDataResult"] != undefined &&
              data["syncDataResult"]["respCode"] == 200 &&
              data["syncDataResult"]["affectedRowsCount"] != undefined
            ) {
              this.dataToBeProcessed[this.processingRowIndex].syncStatus =
                data["syncStatus"];
            } else if (data["syncDataResult"] != undefined) {
              this.dataToBeProcessed[this.processingRowIndex].syncStatus =
                "Fail";
              this.dataToBeProcessed[this.processingRowIndex].syncDataResult = {
                errorDetail:
                  "REDCap Response: " +
                  data["syncDataResult"]["errorDetail"] +
                  " Response code: " +
                  data["syncDataResult"]["respCode"],
              };
            }
          } else {
            this.dataToBeProcessed[this.processingRowIndex].syncDataResult = {
              errorDetail:
                "Something went wrong. Please screenshot the whole page especially the line where the error happen and send it to your developer.",
            };
          }
          this.processingRowIndex++;
          this.uploadData();
        },
        (err) => {
          this.errorService.networkError();
        }
      );
    }
  }

  private fetchDataToBeProcessed() {
    this.httpService
      .get(`./api/redcap/total?projectUid=${this.projectUid}`, null)
      .subscribe(
        (data: Array<any>) => {
          if (data != undefined) {
            this.dataToBeProcessed = [];
            for (let i = 0; i < data.length; i++) {
              this.selectRecords.push(false);
              this.dataToBeProcessed.push(data[i]);
            }
          } else {
            this.errorService.internalError();
          }
        },
        (err) => {
          this.errorService.networkError();
        }
      );
  }

  selectAllRecords(isCheckboxChecked) {
    if (isCheckboxChecked) {
      this.selectRecords = this.selectRecords.map((_) => true);
    } else {
      this.selectRecords = this.selectRecords.map((_) => false);
    }
  }
}
