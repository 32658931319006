import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { endpoints } from "src/app/constants/endpoints";

@Injectable({
  providedIn: "root",
})
export class ProjectCreateService {
  constructor(private http: HttpClient) {}

  deleteProject(projectUid) {
    return this.http
      .delete(`${endpoints.DELETE_PROJECT}?projectUid=${projectUid}`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // get games for the project
  getGamesForTheProject(projectUid) {
    return this.http
      .get(`${endpoints.GET_GAMES_FOR_PROJECT}?projectUid=${projectUid}`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // post the create project API
  createProject(
    projectName,
    description,
    adminEmails,
    logo,
    games,
    dataHandling,
    wizrardMode,
    projectUid
  ) {
    const formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        name: projectName,
        description: description,
        games,
        admin: adminEmails,
        dataHandling,
        ...(wizrardMode === "Edit" && { id: projectUid }),
      })
    );
    formData.append("logo", logo);

    return this.http
      .post(
        wizrardMode === "Create"
          ? endpoints.CREATE_PROJECT
          : endpoints.EDIT_PROJECT,
        formData
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  addUsersToAProject(formData, projectId) {
    return this.http
      .post(
        `${endpoints.ADD_USERS_TO_A_PROJECT}?projectId=${projectId}`,
        formData
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  deleteUserFromProject(formData) {
    return this.http
      .post(`${endpoints.REMOVE_USER_FROM_PROJECT}`, formData)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  editUserRoleInProject(formData) {
    return this.http
      .put(`${endpoints.EDIT_USER_ROLE_IN_PROJECT}`, formData)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
}
