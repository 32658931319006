import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin } from "rxjs";
import { ChildAuthService } from "src/app/services/auth/child-auth.service";
import { GetprojectsService } from "src/app/services/getprojects/getprojects.service";

@Component({
  selector: "app-edit-project",
  templateUrl: "./edit-project.component.html",
  styleUrls: ["./edit-project.component.css"],
})
export class EditProjectComponent implements OnInit {
  projectUid: any;
  isAdmin: boolean;

  // project fields for existing project
  projectName: any;
  projectDescription: any;
  projectGames: any;
  projectDataStrategy: any;
  projectAdminEmails: any;
  projectLogoUrl: any;

  isUserInProject: any = {};
  loadingText: any;

  constructor(
    private route: ActivatedRoute,
    private getProjectService: GetprojectsService,
    private spinner: NgxSpinnerService,
    authService: ChildAuthService
  ) {
    this.isAdmin = authService.loggedInDetails.isAdmin;
  }

  ngOnInit(): void {
    this.projectUid = this.route.snapshot.paramMap.get("projectUid");
    this.loadingText = "Loading Project ...";
    this.spinner.show();
    forkJoin(
      this.getProjectService.getProject(this.projectUid, true),
      this.getProjectService.isUserAProjectAdmin(this.projectUid)
    ).subscribe(([projectDetails, isProjectAdmin]) => {
      this.projectName = projectDetails.name;
      this.projectDescription = projectDetails.description;
      this.projectGames = projectDetails.games;
      this.projectDataStrategy = projectDetails.dataHandling;
      this.projectAdminEmails = projectDetails.admins;
      this.projectLogoUrl = projectDetails.logo;
      this.isUserInProject = isProjectAdmin;
      this.spinner.hide();
    });
  }
}
