import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { DataStorage } from "src/app/constants/dataStorage";
import { ChildAuthService } from "src/app/services/auth/child-auth.service";
import { GetprojectsService } from "src/app/services/getprojects/getprojects.service";
@Component({
  selector: "app-project-details",
  templateUrl: "./project-details.component.html",
  styleUrls: ["./project-details.component.css"],
})
export class ProjectDetailsComponent implements OnInit {
  exportOptions = [
    {
      item_id: DataStorage.DropBox,
      item_text: "Dropbox",
    },
    {
      item_id: DataStorage.Box,
      item_text: "Box",
    },
  ];

  projectExportChoice: String;
  projectDetails: any = {};
  projectUid: String;
  isAdmin: Boolean;
  isUserInProject: any = {};
  loadingText: String;
  isDeleteModalOpen: Boolean = false;
  deleteProjectDetails: any = {};

  constructor(
    private getProjectService: GetprojectsService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private authService: ChildAuthService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.isAdmin = authService.loggedInDetails.isAdmin;
  }

  saveProjectExport() {
    // open a popup with the data export provider's auth url
    this.authService
      .authorizeExportLocation(this.projectExportChoice, this.projectUid)
      .subscribe((d) => {
        const authPopup = window.open(
          d.redirect_url,
          Math.random().toString(),
          "width=500,height=500"
        );
        const timer = setInterval(() => {
          // when the popup is closed (user authenticates with data export platform fetch updated project details)
          if (authPopup.closed) {
            clearInterval(timer);
            this.loadingText = "Loading...";
            this.spinner.show();
            this.getProjectService
              .getProject(this.projectUid)
              .subscribe((projectDetails) => {
                this.projectDetails = projectDetails;
                this.projectExportChoice = projectDetails.dataExportLocation;
                this.deleteProjectDetails = {
                  projectUid: projectDetails.uid,
                  projectName: projectDetails.name,
                };
                this.spinner.hide();
              });
          }
        }, 1000);
      });
  }

  openEditProject() {
    this.router.navigate(["app/project/", this.projectUid, "editProject"]);
  }

  openRecordingTest() {
    this.router.navigate(["app/project/", this.projectUid, "recordingTest"]);
  }

  deleteModalClose() {
    this.isDeleteModalOpen = false;
  }

  onProjectDelete() {
    setTimeout(() => {
      this.router.navigate(["app/projects"]);
    }, 2000);
  }

  openDeleteProjectModal() {
    this.isDeleteModalOpen = true;
  }

  // navigate to project manage users path
  openManageUsers() {
    this.router.navigate(["app/project/", this.projectUid, "manageUsers"]);
  }

  openExportGameData() {
    this.router.navigate(["app/project/", this.projectUid, "exportGameData"]);
  }

  copyToClipboard(item) {
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
    this.toastr.success("Copied to clipboard");
  }

  // fetch project details on page load
  ngOnInit(): void {
    this.loadingText = "Loading...";
    this.spinner.show();
    this.projectUid = this.route.snapshot.paramMap.get("projectUid");
    forkJoin(
      this.getProjectService.getProject(this.projectUid),
      this.getProjectService.isUserAProjectAdmin(this.projectUid)
    ).subscribe(([projectDetails, isProjectAdmin]) => {
      this.projectDetails = projectDetails;
      this.projectExportChoice = projectDetails.dataExportLocation;
      this.deleteProjectDetails = {
        projectUid: projectDetails.uid,
        projectName: projectDetails.name,
      };
      this.isUserInProject = isProjectAdmin;
      this.spinner.hide();
    });
  }

  // check if the games can be started. If the data handling strategy is ALL, start it
  // if its NONE check if an export location has been configured, only then allow the games to be played
  canProjectStartGames() {
    if (this.projectDetails.dataHandling === "ALL") {
      return true;
    }

    if (this.projectDetails.dataHandling === "NONE") {
      if (this.projectDetails.dataExportLocation === null) {
        return false;
      }
      return true;
    }
    return true;
  }
}
