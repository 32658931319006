// const file for all the endpoints mapping
export const endpoints = {
  LOGIN_API: "/auth/oauth/token", // Login api return auth token on valid creds
  CHECK_SESSION: "/checkSession", // check session details
  LOGOUT: "/logout", // log out user
  CREATE_PROJECT: "/api/project", // create project with this api
  VERIFY_INVITE: "/api/invite/verifyInvite", // verify if the invite is valid
  CREATE_USER_AND_ADD_PROJECT: "/api/invite/createUserAndAddProject", // create new user and add them to the project
  GET_PROJECTS: "/api/projects", // get all projects
  GET_PROJECT_IMAGE: "/api/getImage", // get project logo and other images
  GET_USERS: "/api/user/getUsers", // get users in typeahead
  GET_PROJECT: "/api/project", // get details for a project
  GET_PROJECT_USERS: "/api/project/users", // get users in a project
  GET_USERS_NOT_IN_A_PROJECT: "/api/user/getUsersNotInaProject", // get users not in a project
  ADD_USERS_TO_A_PROJECT: "/api/project/addUsersToProject", // add users to a project
  IS_USER_IN_A_PROJECT: "/api/project/isUserInProject", // check if user is in a project
  REMOVE_USER_FROM_PROJECT: "/api/project/removeUserFromProject", // remove user from a project
  RESEND_INVITE: "/api/invite/resendInvite", // resend invite for a project
  DELETE_INVITE: "/api/invite/deleteInvite", // delete sent invite for a project
  EDIT_USER_ROLE_IN_PROJECT: "/api/project/editUserRoleInProject", // edit user role in a project
  GET_ALL_USERS: "/api/user/getAllUsers", // get all users in the project
  DELETE_USER: "/api/user/deleteUser", // delete the user's account,
  ASSIGN_ADMIN_ROLE: "/auth/assignAdminRole", // assigning the admin role to the user account
  REMOVE_ADMIN_ROLE: "/auth/removeAdminRole", // remove admin role to the user account
  ADD_ADMINS: "/api/user/addAdmins", // add admins to the app
  GET_PENDING_ADMIN_INVITES: "/api/invite/pendingAdminInvites", // get all the pending admin invites,
  CREATE_USER_AND_ASSIGN_ADMIN_ROLE: "/api/invite/createUserAndAssignAdminRole", // create user and assign them the admin role
  DELETE_PROJECT: "/api/project/deleteProject", // delete user project
  BOX_AUTH_URL: "/authorize/box", // authorize box account of the user,
  DROPBOX_AUTH_URL: "/authorize/dropbox",
  GET_GAMES_FOR_PROJECT: "/api/project/getGamesForTheProject", // get games for a project
  CHECK_IF_GAME_IN_PROJECT: "/api/project/checkIfGameInProject", // check if the game has been assigned to the project
  EXPORT_PROJECT_DATA: "/api/project/exportProjectData", // export game data for a project to a csv in the export location
  EDIT_PROJECT: "/api/project/editProject", // Edit project details
};
