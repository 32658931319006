import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { AudioRecordService } from "../../services/utils/audio-record.service";
import { ErrorService } from "../../services/errors/error.service";
import { FileService } from "../../services/files/file.service";

@Component({
  selector: "app-audio-record",
  templateUrl: "./audio-record.component.html",
  styleUrls: ["./audio-record.component.css"],
})
export class AudioRecordComponent implements OnInit {
  projectUid: any; //Stores current projectUid for file saving calls

  constructor(
    private audioRecordService: AudioRecordService,
    private errorService: ErrorService,
    private fileService: FileService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.projectUid = this.route.snapshot.paramMap.get("projectUid");
    this.startRecording = false;
  }

  //public variable
  fileUid;
  startRecording;

  startRecord() {
    this.audioRecordService.startRecord();
    this.startRecording = true;
  }

  endRecord() {
    this.audioRecordService.stopRecord().then((blob: any) => {
      this.startRecording = false;
      let formData = new FormData();
      formData.append("Test_Recording", blob, "Test_Recording");
      formData.append("projectUid", this.projectUid);
      this.audioRecordService
        .sendAudioData("./api/audiorecord", formData)
        .subscribe(
          (data) => {
            let result = data;
            if (result != undefined) {
              this.fileUid = result["documentId"];
              setTimeout(() => {
                document.getElementById("test-audio-div").innerHTML =
                  "<audio controls>" +
                  "<source src='./api/audiorecord/play?fileUid=" +
                  this.fileUid +
                  "' type='audio/mp3' /> " +
                  "</audio>";
              }, 0);
            } else {
              this.errorService.internalError();
            }
          },
          (err) => {
            this.errorService.internalError();
          }
        );
    });
  }

  downloadFile() {
    this.fileService
      .downloadFile(
        "./api/audiorecord/download",
        { fileUid: this.fileUid },
        () => {}
      )
      .subscribe();
  }
}
