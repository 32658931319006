import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { endpoints } from "src/app/constants/endpoints";

@Injectable({
  providedIn: "root",
})
export class InviteService {
  constructor(private http: HttpClient) {}

  // check if the token with the invite is valid
  verifyInvite(token) {
    return this.http
      .post(
        endpoints.VERIFY_INVITE,
        {},
        {
          params: {
            token,
          },
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // create user account (post the details)
  createAccount(token, password, firstName, lastName, isAdminInvite) {
    return this.http
      .post(
        isAdminInvite
          ? endpoints.CREATE_USER_AND_ASSIGN_ADMIN_ROLE
          : endpoints.CREATE_USER_AND_ADD_PROJECT,
        {
          password,
          firstName,
          lastName,
        },
        {
          params: {
            token,
          },
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  resendInvite(inviteUid) {
    return this.http
      .post(
        endpoints.RESEND_INVITE,
        {},
        {
          params: {
            inviteUid,
          },
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  deleteInvite(inviteUid) {
    return this.http
      .delete(endpoints.DELETE_INVITE, {
        params: {
          inviteUid,
        },
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
}
