import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { endpoints } from "src/app/constants/endpoints";

export interface Person {
  uid: string;
  username: string;
  userStatus: string;
}

@Injectable({
  providedIn: "root",
})
export class GetusersNotInAProjectService {
  private _emailsCache = new Map<string, []>();

  constructor(private http: HttpClient) {}

  getPeople(term: string = null, projectId): Observable<any> {
    if (this._emailsCache.has(term)) {
      return of(this._emailsCache.get(term));
    }

    return this.http
      .get<any>(
        `${endpoints.GET_USERS_NOT_IN_A_PROJECT}?query=${term}&projectId=${projectId}`
      )
      .pipe(
        map((rsp) => {
          let items = rsp;
          if (term) {
            items = items.filter(
              (x) =>
                x.username
                  .toLocaleLowerCase()
                  .indexOf(term.toLocaleLowerCase()) > -1
            );
          }
          return items;
        }),
        tap((data: any) => this._emailsCache.set(term, data))
      );
  }
}
