export const games = [
  {
    id: "VSR",
    text: "Visual Span Running",
  },
  {
    id: "RDV",
    text: "Repetition Detection - Visual",
  },
  {
    id: "LS",
    text: "Location Span",
  },
  {
    id: "NUA",
    text: "Number Updating - Auditory",
  },
  {
    id: "PBS",
    text: "Phonological Binding Span",
  },
  {
    id: "VBS",
    text: "Visual Binding",
  },
  {
    id: "DS",
    text: "Digit Span",
  },
  {
    id: "CMB",
    text: "Cross Modal Binding",
  },
  {
    id: "LSR",
    text: "Location Span Running",
  },
  {
    id: "RDA",
    text: "Repetition Detection - Auditory",
  },
  {
    id: "DSR",
    text: "Digit Span Running",
  },
  {
    id: "NUV",
    text: "Number Updating - Visual",
  },
  {
    id: "NR",
    text: "Nonword Repetition",
  },
  {
    id: "VS",
    text: "Visual Span",
  },
];

export enum GameIds {
  VSR = "VSR",
  RDV = "RDV",
  LS = "LS",
  NUA = "NUA",
  PBS = "PBS",
  VBS = "VBS",
  DS = "DS",
  CMB = "CMB",
  LSR = "LSR",
  RDA = "RDA",
  DSR = "DSR",
  NUV = "NUV",
  NR = "NR",
  VS = "VS",
}
