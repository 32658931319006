import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { endpoints } from "src/app/constants/endpoints";

@Injectable({
  providedIn: "root",
})
export class GamesInProjectService {
  constructor(private http: HttpClient, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      this.http
        .get(
          `${endpoints.CHECK_IF_GAME_IN_PROJECT}?projectUid=${route.params.projectUid}&gameId=${route.data.gameId}`
        )
        .subscribe(
          (data: any) => {
            if (data) {
              resolve(true);
            } else {
              window.alert(
                "You are not authorized to view this page. You will be redirected to the home page"
              );
              this.router.navigateByUrl("app/projects");
              resolve(false);
            }
          },
          (error) => {
            window.alert(error);
            this.router.navigateByUrl("app/projects");
          }
        );
    });
  }
}
