import { Component, OnInit } from "@angular/core";
import { LoginoauthService } from "src/app/services/loginoauth/loginoauth.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { ChildAuthService } from "src/app/services/auth/child-auth.service";

@Component({
  selector: "app-loginoauth",
  templateUrl: "./loginoauth.component.html",
  styleUrls: ["./loginoauth.component.css"],
})
export class LoginoauthComponent implements OnInit {
  username: String;
  password: String;
  errorMessage: String;
  showMessage = null;

  constructor(
    private oauthService: LoginoauthService,
    private authService: ChildAuthService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  // if the page is being routed from the user sign up page display a message asking the user to log in again
  ngOnInit() {
    this.showMessage = this.route.snapshot.queryParams["showMessage"];
    this.authService.canActivate().then((_) => {
      // if user already logged in take them to the landing page
      if (
        this.authService.loggedInDetails &&
        this.authService.loggedInDetails.isLoggedIn
      ) {
        this.router.navigateByUrl("/app/projects");
      }
    });
  }

  // make API call to the login API with the username & password entered in the text field to log in.
  login() {
    this.oauthService.logIn(this.username, this.password).subscribe(
      (data) => {
        if (data.login) {
          this.router.navigateByUrl("/app/projects");
        }
      },
      (err) => {
        if (err.status === 401) {
          this.toastr.error("Invalid user credentials", "Login Failed");
        } else {
          this.toastr.error(err.error, "Something went wrong");
        }
      }
    );
  }
}
