import { Component, Input, OnInit } from "@angular/core";
import { games } from "src/app/constants/games";
import { ChildAuthService } from "src/app/services/auth/child-auth.service";
import { ProjectCreateService } from "src/app/services/projectcreate/project-create-service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { endpoints } from "../../constants/endpoints";
import { concat, Observable, of, Subject } from "rxjs";
import {
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import {
  LoadAdminService,
  Person,
} from "../../services/loadadmin/loadadmin.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-project-wizard",
  templateUrl: "./project-wizard.component.html",
  styleUrls: ["./project-wizard.component.css"],
})
export class ProjectWizardComponent implements OnInit {
  dropdownList = [];
  dropdownSettings = {};
  isUserAdmin: Boolean;
  selectedItems = [];
  projectName: String;
  file: any;
  url;
  projectDescription: String;
  dataStrategy: any;
  loadingText: String;

  people$: Observable<Person[]>;
  peopleLoading = false;
  peopleInput$ = new Subject<string>();
  selectedPersons: Person[] = [];

  wizardMode: any;

  projectUid: any;

  @Input() set name(value: any) {
    this.projectName = value;
  }

  @Input() set description(value: any) {
    this.projectDescription = value;
  }

  @Input() set dataStrategyInput(value: any) {
    this.dataStrategy = value;
  }

  @Input() set games(value: any) {
    if (value) {
      this.selectedItems = value.map((e) => {
        return {
          item_id: e,
          item_text: games.find((elem) => elem.id === e).text,
        };
      });
    }
  }

  @Input() set adminEmails(value: any) {
    if (value) {
      this.selectedPersons = value.map((e) => {
        return {
          username: e,
        };
      });
    }
  }

  @Input() set logoUrl(value: any) {
    if (value) {
      this.url = `${endpoints.GET_PROJECT_IMAGE}?imagePath=${encodeURIComponent(
        value
      )}`;
    }
  }

  @Input() set projectWizardMode(value: any) {
    this.wizardMode = value;
  }

  // check if the user logged is in admin, if not don't allow user to view the page.
  constructor(
    authService: ChildAuthService,
    private projectCreate: ProjectCreateService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private loadAdminService: LoadAdminService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.isUserAdmin = authService.loggedInDetails.isAdmin;
  }

  // load existing user's from the back end service based on the query entered.
  private loadPeople() {
    this.people$ = concat(
      of([]), // default items
      this.peopleInput$.pipe(
        distinctUntilChanged(),
        tap(() => (this.peopleLoading = true)),
        switchMap((term) =>
          this.loadAdminService.getPeople(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.peopleLoading = false))
          )
        )
      )
    );
  }

  trackByFn(item: Person) {
    return item.uid;
  }

  // init drop downs from the config variable
  ngOnInit(): void {
    this.loadPeople();

    this.projectUid = this.route.snapshot.paramMap.get("projectUid");

    this.dropdownList = games.map((game) => {
      return {
        item_id: game.id,
        item_text: game.text,
      };
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
  }

  // function called on the create project button, gather all the data from the input fields and post it to the backend
  createProject() {
    this.loadingText = "Creating Project..";
    this.spinner.show();
    this.projectCreate
      .createProject(
        this.projectName,
        this.projectDescription,
        this.selectedPersons.map((e) => e.username.trim()),
        this.file,
        this.selectedItems.map((e) => e.item_id),
        this.dataStrategy,
        this.wizardMode,
        this.projectUid
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          this.toastr.success(
            `Project - ${data.name} ${this.wizardMode}ed successfully`
          );
          setTimeout(() => {
            this.router.navigateByUrl("/app/projects");
          }, 2000);
          this.selectedItems = [];
          this.selectedPersons = [];
          this.projectName = "";
          this.file = null;
          this.projectDescription = "";
          this.dataStrategy = null;
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.error, "Something went wrong!");
        }
      );
  }

  // see if the emails entered in the project admins input field are valid
  isValidEmails(selectedPersons) {
    return selectedPersons.every((person) =>
      this.validateEmail(person.username)
    );
  }

  // check with email regex.
  validateEmail(email) {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // function called to display preview of selected project logo
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url
      this.file = event.target.files[0];
      reader.onload = (ev) => {
        // called once readAsDataURL is completed
        this.url = ev.target.result;
      };
    }
  }

  // get items for the games dropdown list
  get getItems() {
    return this.dropdownList.reduce((acc, curr) => {
      acc[curr.item_id] = curr;
      return acc;
    }, {});
  }
}
