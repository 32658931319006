import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { endpoints } from "src/app/constants/endpoints";

@Injectable({
  providedIn: "root",
})
export class GetprojectsService {
  private _projectCache = new Map<string, {}>();

  constructor(private http: HttpClient) {}

  // get the project details if not in cache
  getProject(projectUid, projectAdminDetails = false) {
    if (this._projectCache.has(projectUid)) {
      return of(this._projectCache.get(projectUid));
    } else {
      return this.http
        .get(
          `${endpoints.GET_PROJECT}?projectUid=${projectUid}&projectAdminDetails=${projectAdminDetails}`
        )
        .pipe(
          map((response: any) => {
            return response;
          })
        );
    }
  }

  // get users in a project
  getProjectUsers(projectUid) {
    return this.http
      .get(`${endpoints.GET_PROJECT_USERS}?projectUid=${projectUid}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  // get all projects
  getProjects() {
    return this.http.get(endpoints.GET_PROJECTS).pipe(
      map((response: any) => {
        response.otherProjects.forEach((element) => {
          this._projectCache.set(element.uid, element);
        });

        response.yourProjects.forEach((element) => {
          this._projectCache.set(element.uid, element);
        });

        return response;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  // check if user in a project
  isUserAProjectAdmin(projectId) {
    return this.http
      .get(`${endpoints.IS_USER_IN_A_PROJECT}?projectId=${projectId}`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
}
