import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { map } from "rxjs/operators";
import { DataStorageAuthEndpointKeys } from "src/app/constants/dataStorage";
import { endpoints } from "src/app/constants/endpoints";

@Injectable({
  providedIn: "root",
})
export class ChildAuthService implements CanActivate {
  loggedInDetails: any;

  constructor(private router: Router, private http: HttpClient) {}

  // see if the user is logged in ony then allow to view route else redirect to login.
  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.http.get(endpoints.CHECK_SESSION).subscribe((data: any) => {
        if (data.isLoggedIn) {
          this.loggedInDetails = data;
          resolve(true);
        } else {
          this.router.navigateByUrl("app/loginoauth");
          resolve(false);
        }
      });
    });
  }

  authorizeExportLocation(type, projectUid) {
    return this.http
      .get(
        `${
          endpoints[DataStorageAuthEndpointKeys[type]]
        }?projectUid=${projectUid}`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
