import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { StoreService } from "../../services/store/store.service";
import { AppSessionService } from "../../services/session/app-session.service";
import { ErrorService } from "../../services/errors/error.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  studentName: String;
  experimenter: String;
  grade: String;
  projectUid: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: AppSessionService,
    private errorService: ErrorService,
    private storeService: StoreService
  ) {}

  ngOnInit() {
    this.projectUid = this.route.snapshot.paramMap.get("projectUid");
    this.initStore();
    if (sessionStorage.getItem("childId_" + this.projectUid) !== undefined) {
      this.studentName = sessionStorage.getItem("childId_" + this.projectUid);
    }

    this.experimenter = localStorage.getItem("name");

    if (sessionStorage.getItem("grade_" + this.projectUid) !== undefined) {
      this.grade = sessionStorage.getItem("grade_" + this.projectUid);
    }
  }

  // after enter student id and experimenter name
  login(): void {
    if (sessionStorage.length > 0) {
      sessionStorage.clear();
    }
    sessionStorage.setItem(
      "childId_" + this.projectUid,
      this.studentName.toString()
    );
    sessionStorage.setItem(
      "experimenter_" + this.projectUid,
      this.experimenter.toString()
    );
    sessionStorage.setItem("grade_" + this.projectUid, this.grade.toString());
    // http
    const data = {
      studentId: this.studentName,
      experimenter: this.experimenter,
      grade: this.grade,
      projectUid: this.projectUid,
    };

    this.sessionService
      .saveChildIdAndExperimenter("./api/childid/save", data, () => {})
      .subscribe(
        (d) => {
          if (d) {
            if (this.projectUid) {
              this.router.navigate(["app/", this.projectUid, "dashboard"]);
            } else {
              this.router.navigate(["app/dashboard"]);
            }
          } else {
            this.errorService.internalError();
          }
        },
        (err) => {
          this.errorService.networkError();
        }
      );
  }

  private initStore() {
    this.storeService
      .initStore(
        `./api/home/storeinit?projectUid=${this.projectUid}`,
        null,
        () => {}
      )
      .subscribe(
        (data) => {
          if (data === undefined || !data) {
            this.errorService.internalError();
          }
        },
        (err) => {
          this.errorService.networkError();
        }
      );
  }
}
