import { Component, OnInit } from "@angular/core";

import { ProfileService } from "../../services/profile/profile.service";
import { ErrorService } from "../../services/errors/error.service";
import { RandomNumberService } from "../../services/utils/random-number.service";
import { GameProgressService } from "../../services/game-progress/game-progress.service";
import { GeneralHttpService } from "../../services/utils/general-http.service";
import { ActivatedRoute } from "@angular/router";
import { ProjectCreateService } from "src/app/services/projectcreate/project-create-service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  // consts
  private static introVideoSource = [
    "../../../assets/videos/introduction/Intro_1.mp4",
    "../../../assets/videos/introduction/Intro_2.mp4",
    "../../../assets/videos/introduction/Intro_3.mp4",
    "../../../assets/videos/introduction/Intro_4.mp4",
  ];
  private static day1IntroVideosSource = [
    "../../../assets/videos/introduction/day1/Intro_1.mp4",
    "../../../assets/videos/introduction/day1/Intro_2.mp4",
    "../../../assets/videos/introduction/day1/Intro_3.mp4",
    "../../../assets/videos/introduction/day1/Intro_4.mp4",
    "../../../assets/videos/introduction/day1/Intro_5.mp4",
  ];
  private static day2IntroVideosSource = [
    "../../../assets/videos/introduction/day2/Intro_1.mp4",
  ];
  private static introContinueVideoSource = [
    "../../../assets/videos/introduction/day1/Intro_6.mp4",
    "../../../assets/videos/introduction/day1/Intro_7.mp4",
    "../../../assets/videos/introduction/day1/Intro_8.mp4",
    "../../../assets/videos/introduction/day1/Intro_9.mp4",
    "../../../assets/videos/introduction/day1/Intro_10.mp4",
    "../../../assets/videos/introduction/day1/Intro_11.mp4",
    "../../../assets/videos/introduction/day1/Intro_12.mp4",
  ];
  private static intro2ContinueVideoSource = [
    "../../../assets/videos/introduction/day2/Intro_2.mp4",
    "../../../assets/videos/introduction/day2/Intro_3.mp4",
    "../../../assets/videos/introduction/day2/Intro_4.mp4",
  ];

  // removing static since the array needs to be modified on ngInit again
  day1Games = [
    { id: "DS", name: "Digit Span", link: "/app/digitspan", order: "" },
    {
      id: "VSR",
      name: "Visual Span Running",
      link: "/app/visualspanrunning",
      order: "",
    },
    { id: "LS", name: "Location Span", link: "/app/locationspan", order: "" },
    {
      id: "NUA",
      name: "Number Updating - Auditory",
      link: "/app/numberupdateauditory",
      order: "",
    },
    {
      id: "RDV",
      name: "Repetition Detection - Visual",
      link: "/app/repetitionvisual",
      order: "",
    },
    {
      id: "PBS",
      name: "Phonological Binding Span",
      link: "/app/phonological",
      order: "",
    },
    {
      id: "VBS",
      name: "Visual Binding",
      link: "/app/visualbinding",
      order: "",
    },
  ];

  day2Games = [
    {
      id: "DSR",
      name: "Digit Span Running",
      link: "/app/digitspanrunning",
      order: "",
    },
    { id: "VS", name: "Visual Span", link: "/app/visualspan", order: "" },
    {
      id: "LSR",
      name: "Location Span Running",
      link: "/app/locationspanrunning",
      order: "",
    },
    {
      id: "NUV",
      name: "Number Updating - Visual",
      link: "/app/numberupdatevisual",
      order: "",
    },
    {
      id: "RDA",
      name: "Repetition Detection - Auditory",
      link: "/app/repetitionauditory",
      order: "",
    },
    { id: "NR", name: "Nonword Repetition", link: "/app/nonword", order: "" },
    {
      id: "CMB",
      name: "Cross Modal Binding",
      link: "/app/crossmodalbinding",
      order: "",
    },
  ];

  currentStudent: String;
  grade: String;
  gameMode: String;
  pirateGender: String;
  pirateCharacter: String;
  pirateOptions: String[];
  listOfGames;
  gameIds;
  showGameStore;
  projectUid: any;

  //private variables
  private indexOfVideo;
  private indexOfContinueVideo;
  private continueVideosSource;
  private noOfCompleteGames;

  constructor(
    private profileService: ProfileService,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private randomService: RandomNumberService,
    private gameProgressService: GameProgressService,
    private httpService: GeneralHttpService,
    private projectService: ProjectCreateService
  ) {
    this.projectUid = this.route.snapshot.paramMap.get("projectUid");
    this.currentStudent = sessionStorage.getItem("childId_" + this.projectUid);
    this.grade = sessionStorage.getItem("grade_" + this.projectUid);
  }

  ngOnInit() {
    this.indexOfVideo = 1;
    this.indexOfContinueVideo = 1;
    this.fetchStudentInfo();
    // this.gameMode = "game";

    // update the links with the project uid
    this.day2Games = this.day2Games.map((e) => {
      const linkParts = e.link.split("/");
      return {
        ...e,
        link: `/${linkParts[1]}/${this.projectUid}/${linkParts[2]}`,
      };
    });

    // update the links with the project uid
    this.day1Games = this.day1Games.map((e) => {
      const linkParts = e.link.split("/");
      return {
        ...e,
        link: `/${linkParts[1]}/${this.projectUid}/${linkParts[2]}`,
      };
    });

    this.projectService
      .getGamesForTheProject(this.projectUid)
      .subscribe((data) => {
        this.day1Games = this.day1Games.filter((e) => data.includes(e.id));
        this.day2Games = this.day2Games.filter((e) => data.includes(e.id));
      });

    this.initSyncData();
  }

  playDay1IntroVideos(): void {
    const videoId = "intro-video";
    const source = DashboardComponent.day1IntroVideosSource;
    if (this.indexOfVideo < source.length) {
      document
        .getElementById(videoId)
        .setAttribute("src", source[this.indexOfVideo]);
      document.getElementById(videoId).setAttribute("type", "video/mp4");
      const videoSource = document.getElementById(videoId).getAttribute("src");
      (document.getElementById(videoId) as HTMLVideoElement).load();
      (document.getElementById(videoId) as HTMLVideoElement).play();
      this.indexOfVideo++;
    } else {
      document
        .getElementById(videoId)
        .removeEventListener("ended", this.playDay1IntroVideos);
      this.gameMode = "intro-gender";
      const audio = new Audio();
      audio.src = "../../../assets/audios/introduction/Female_Or_Male_1.mp3";
      audio.load();
      audio.play();
      audio.addEventListener("ended", () => {
        const audio2 = new Audio();
        audio2.src = "../../../assets/audios/introduction/Female_Or_Male_2.mp3";
        audio2.load();
        audio2.play();
        audio2.addEventListener("ended", () => {
          document.getElementById("next-btn").style.display = "block";
        });
      });
      // audio.addEventListener("ended", () => {document.getElementById("next-btn").style.display = "block"});
    }
  }

  playDay2IntroVideos(): void {
    const videoId = "intro-video";
    const source = DashboardComponent.day2IntroVideosSource;
    if (this.indexOfVideo < source.length) {
      document
        .getElementById(videoId)
        .setAttribute("src", source[this.indexOfVideo]);
      document.getElementById(videoId).setAttribute("type", "video/mp4");
      document.getElementById(videoId).getAttribute("src");
      (document.getElementById(videoId) as HTMLVideoElement).load();
      (document.getElementById(videoId) as HTMLVideoElement).play();
      this.indexOfVideo++;
    } else {
      document
        .getElementById(videoId)
        .removeEventListener("ended", this.playDay2IntroVideos);
      this.gameMode = "intro2-gender";
      const audio = new Audio();
      audio.src =
        "../../../assets/audios/introduction/day2/Female_Or_Male_1.mp3";
      audio.load();
      audio.play();
      audio.addEventListener("ended", () => {
        const audio2 = new Audio();
        audio2.src =
          "../../../assets/audios/introduction/day2/Female_Or_Male_2.mp3";
        audio2.load();
        audio2.play();
        audio2.addEventListener("ended", () => {
          const audio3 = new Audio();
          audio3.src =
            "../../../assets/audios/introduction/day2/Female_Or_Male_3.mp3";
          audio3.load();
          audio3.play();
          audio3.addEventListener("ended", () => {
            document.getElementById("next-btn").style.display = "block";
          });
        });
      });
    }
  }

  clickNextBtn(): void {
    if (this.gameMode == "intro-gender") {
      this.gameMode = "intro-pirates";
      if (this.pirateGender === "f") {
        this.pirateOptions = [
          "female-pirate-1",
          "female-pirate-2",
          "female-pirate-3",
          "female-pirate-4",
          "female-pirate-5",
        ];
      } else {
        this.pirateOptions = [
          "male-pirate-1",
          "male-pirate-2",
          "male-pirate-3",
          "male-pirate-4",
          "male-pirate-5",
        ];
      }
      const audio = new Audio();
      audio.src = "../../../assets/audios/introduction/Pick_The_Pirate.mp3";
      audio.load();
      audio.play();
      audio.addEventListener("ended", () => {
        document.getElementById("next-btn").style.display = "block";
      });
    } else if (this.gameMode == "intro-pirates") {
      this.gameMode = "intro-after-pirates";
      //play video. then go to game mode
      setTimeout(() => {
        const audio = new Audio();
        audio.src = "../../../assets/audios/introduction/Great_Choice.mp3";
        audio.load();
        audio.play();
        audio.addEventListener("ended", () => {
          //play intro 5 and 6
          document.getElementById("great-choice-div").style.display = "none";
          document.getElementById("intro-continue-div").style.display = "block";
          this.indexOfContinueVideo = 1;
          this.continueVideosSource =
            DashboardComponent.introContinueVideoSource;
          this.playContinueVideos();
        });
      }, 0);
    }
  }

  clickNextBtn2(): void {
    if (this.gameMode == "intro2-gender") {
      this.gameMode = "intro2-pirates";
      if (this.pirateGender === "f") {
        this.pirateOptions = [
          "female-pirate-1",
          "female-pirate-2",
          "female-pirate-3",
          "female-pirate-4",
          "female-pirate-5",
        ];
      } else {
        this.pirateOptions = [
          "male-pirate-1",
          "male-pirate-2",
          "male-pirate-3",
          "male-pirate-4",
          "male-pirate-5",
        ];
      }
      const audio = new Audio();
      audio.src =
        "../../../assets/audios/introduction/day2/Pick_The_Pirate.mp3";
      audio.load();
      audio.play();
      audio.addEventListener("ended", () => {
        document.getElementById("next-btn").style.display = "block";
      });
    } else if (this.gameMode == "intro2-pirates") {
      this.gameMode = "intro2-after-pirates";
      // play video. then go to game mode
      setTimeout(() => {
        const audio = new Audio();
        audio.src = "../../../assets/audios/introduction/day2/Great_Choice.mp3";
        audio.load();
        audio.play();
        audio.addEventListener("ended", () => {
          // play intro 5 and 6
          document.getElementById("great-choice-div").style.display = "none";
          document.getElementById("intro-continue-div").style.display = "block";
          this.indexOfContinueVideo = 0;
          this.continueVideosSource =
            DashboardComponent.intro2ContinueVideoSource;
          this.playContinueVideos();
        });
      }, 0);
    }
  }

  startGameBtn(): void {
    const formData = {
      childId: this.currentStudent,
      pirateCharacter: this.pirateCharacter,
      intro1Completed: true,
      grade: sessionStorage.getItem("grade_" + this.projectUid),
      projectUid: this.projectUid,
    };
    let result;
    this.profileService
      .updateStudentProfile("./api/dashboard/intro1", formData, () => {})
      .subscribe(
        (data) => {
          result = data;
          if (result) {
            this.gameMode = "game";
            this.showGameStore = false;
          } else {
            this.errorService.internalError();
          }
        },
        (err) => {
          this.errorService.networkError();
        }
      );
  }

  startGame2Btn(): void {
    const formData = {
      childId: this.currentStudent,
      pirateCharacter: this.pirateCharacter,
      intro2Completed: true,
      grade: sessionStorage.getItem("grade_" + this.projectUid),
      projectUid: this.projectUid,
    };
    let result;
    this.profileService
      .updateStudentProfile("./api/dashboard/intro2", formData, () => {})
      .subscribe(
        (data) => {
          result = data;
          if (result) {
            this.gameMode = "game";
            this.showGameStore = false;
          } else {
            this.errorService.internalError();
          }
        },
        (err) => {
          this.errorService.networkError();
        }
      );
  }

  goToDay1Games(): void {
    this.gameMode = "game-day1";
    let list = [];
    for (let i = 0; i < this.day1Games.length; i++) {
      list.push(i);
    }
    list = this.randomService.randomizeAList(list);

    this.listOfGames = {};
    this.gameIds = [];

    for (let i = 0; i < list.length; i++) {
      this.gameIds.push(this.day1Games[list[i]].id);
      this.listOfGames[this.gameIds[i]] = {
        name: this.day1Games[list[i]].name,
        link: this.day1Games[list[i]].link,
        order: "",
      };
    }
    this.fetchListGameProgress("day1");
  }

  goToDay2Games(): void {
    let student;
    const formData = {
      studentId: this.currentStudent,
      grade: this.grade,
      projectUid: this.projectUid,
    };
    this.profileService
      .fetchStudentProfile("./api/dashboard/fetch", formData, () => {})
      .subscribe(
        (data) => {
          student = data;
          if (student != null) {
            if (student.pirateCharacter != undefined) {
              sessionStorage.setItem(
                "pirateCharacter_" + this.projectUid,
                student.pirateCharacter
              );
            }
            if (
              student.day1Completed != undefined &&
              student.day1Completed &&
              (student.intro2Completed == undefined ||
                student.intro2Completed == false)
            ) {
              this.gameMode = "intro-2";
            } else {
              this.gameMode = "game-day2";
              let list = [];
              for (let i = 0; i < this.day2Games.length; i++) {
                list.push(i);
              }
              list = this.randomService.randomizeAList(list);
              this.listOfGames = {};
              this.gameIds = [];
              for (let i = 0; i < list.length; i++) {
                this.gameIds.push(this.day2Games[list[i]].id);
                this.listOfGames[this.gameIds[i]] = {
                  name: this.day2Games[list[i]].name,
                  link: this.day2Games[list[i]].link,
                  order: "",
                };
              }
              this.fetchListGameProgress("day2");
            }
          } else {
            this.errorService.internalError();
          }
        },
        (err) => {
          this.errorService.networkError();
        }
      );
  }

  backToGame(): void {
    this.gameMode = "game";
    this.showGameStore = false;
  }

  private fetchListGameProgress(whichDay) {
    let result;
    this.noOfCompleteGames = 0;
    const url =
      "./api/dashboard/gamelist" +
      "?studentId=" +
      this.currentStudent +
      "&grade=" +
      this.grade +
      `&projectUid=${this.projectUid}`;
    this.gameProgressService
      .fetchListGameProgress(url, this.gameIds, () => {})
      .subscribe(
        (data) => {
          result = data;
          if (result == null) {
            this.errorService.internalError();
          } else if (result.length > 0) {
            // map
            let order = 1;
            for (let i = 0; i < result.length; i++) {
              if (result[i].gameStatus === "Complete") {
                this.noOfCompleteGames++;
                this.listOfGames[result[i].gameId].order = (order++).toString();
              }
            }
            if (whichDay === "day1") {
              this.showGameStore =
                this.noOfCompleteGames === this.day1Games.length;
            } else {
              this.showGameStore =
                this.noOfCompleteGames === this.day2Games.length;
            }
          }
        },
        () => {
          this.errorService.networkError();
        }
      );
  }

  private playContinueVideos = () => {
    const videoId = "intro-continue";
    const source = this.continueVideosSource;
    if (this.indexOfContinueVideo < source.length) {
      document
        .getElementById(videoId)
        .setAttribute("src", source[this.indexOfContinueVideo]);
      document.getElementById(videoId).setAttribute("type", "video/mp4");
      const videoSource = document.getElementById(videoId).getAttribute("src");
      (document.getElementById(videoId) as HTMLVideoElement).load();
      (document.getElementById(videoId) as HTMLVideoElement).play();
      this.indexOfContinueVideo++;
    } else {
      document
        .getElementById(videoId)
        .removeEventListener("ended", this.playContinueVideos);
      document.getElementById("start-game-btn").style.display = "block";
    }
  };

  private fetchStudentInfo() {
    let student;
    const formData = {
      studentId: this.currentStudent,
      grade: this.grade,
      projectUid: this.projectUid,
    };
    this.profileService
      .fetchStudentProfile("./api/dashboard/fetch", formData, () => {})
      .subscribe(
        (data) => {
          student = data;
          if (student != null) {
            if (student.pirateCharacter != undefined) {
              sessionStorage.setItem(
                "pirateCharacter_" + this.projectUid,
                student.pirateCharacter
              );
            }
            if (
              student.intro1Completed == undefined ||
              student.intro1Completed == false
            ) {
              this.gameMode = "intro-1";
            } else {
              this.gameMode = "game";
            }
          } else {
            this.errorService.internalError();
          }
        },
        (err) => {
          this.errorService.networkError();
        }
      );
  }

  private initSyncData() {
    const formData = {
      studentId: sessionStorage.getItem("childId_" + this.projectUid),
      grade: sessionStorage.getItem("grade_" + this.projectUid),
      projectUid: this.projectUid,
    };
    this.httpService.post("./api/dashboard/syncdata", formData).subscribe(
      (data) => {
        if (data != null && data["errorType"] != undefined) {
          this.errorService.internalError();
        }
      },
      () => {
        this.errorService.networkError();
      }
    );
  }
}
