import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { InviteService } from "src/app/services/invite-service/invite.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-invite-validate",
  templateUrl: "./invite-validate.component.html",
  styleUrls: ["./invite-validate.component.css"],
})
export class InviteValidateComponent implements OnInit {
  inviteToken = null;
  validInvite = true;
  isUserSignedUp = true;
  expiredInvite = false;
  adminInvite = false;
  errors = [];
  firstName: String;
  lastName: String;
  password: String;
  uName: String;
  loadingText: String;

  constructor(
    private route: ActivatedRoute,
    private inviteService: InviteService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  // function called on create account btn, display spinner and init api call to store user details
  createAccount() {
    this.loadingText = "Signing you up..";
    this.spinner.show();
    this.inviteService
      .createAccount(
        this.inviteToken,
        this.password,
        this.firstName,
        this.lastName,
        this.adminInvite
      )
      .subscribe((data) => {
        this.spinner.hide();
        this.router.navigateByUrl("/app/loginoauth?showMessage=true");
      });
  }

  // check if the password meets req's set.
  validatePassword(p) {
    if (p === undefined) {
      return false;
    }
    this.errors = [];
    if (p.length < 8) {
      this.errors.push("Your password must be at least 8 characters.");
    }
    if (p.search(/[a-z]/i) < 0) {
      this.errors.push("Your password must contain at least one letter.");
    }
    if (p.search(/[0-9]/) < 0) {
      this.errors.push("Your password must contain at least one digit.");
    }
    if (this.errors.length > 0) {
      return false;
    }
    return true;
  }

  // on page load verify the token in the query param - token.
  ngOnInit(): void {
    this.inviteToken = this.route.snapshot.queryParams["token"];
    this.inviteService.verifyInvite(this.inviteToken).subscribe((data) => {
      this.validInvite = data.isInviteValid;
      this.isUserSignedUp = data.isUserSignedUp;
      this.uName = data.username;
      this.expiredInvite = data.isInviteExpired;
      this.adminInvite = data.adminInvite;
      if (data.isInviteValid && data.isUserSignedUp) {
        this.router.navigateByUrl("/app/projects");
      }
    });
  }
}
