import { Component, Input, Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ProjectCreateService } from "src/app/services/projectcreate/project-create-service";

@Component({
  selector: "app-delete-project",
  templateUrl: "./delete-project.component.html",
  styleUrls: ["./delete-project.component.css"],
})
export class DeleteProjectComponent {
  @Input() openModal: Boolean;
  @Input() projectDetails: any;
  @Output() modalClose = new EventEmitter();
  @Output() onProjectDelete = new EventEmitter();
  loadingText: String;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private projectService: ProjectCreateService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  // emit to the event emitter on output
  onModalClose() {
    this.modalClose.emit(null);
  }

  // delete the project by making API call
  deleteProject(projectUid) {
    this.spinner.show();
    this.loadingText = "Deleting project ...";
    this.projectService.deleteProject(projectUid).subscribe((_) => {
      this.onProjectDelete.emit(null);
      this.spinner.hide();
      this.ngxSmartModalService.getModal("deleteProjectModal").close();
      this.toastr.success("Project deleted successfully");
    });
  }

  ngOnChanges(changes: any) {
    if (changes.openModal && changes.openModal.currentValue) {
      this.ngxSmartModalService.getModal("deleteProjectModal").open();
    }
  }
}
