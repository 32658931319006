import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { endpoints } from "src/app/constants/endpoints";
import { catchError, map } from "rxjs/operators";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoginoauthService {
  constructor(private http: HttpClient) {}

  // post req to log in into the app
  public logIn(username, password) {
    const formData = new URLSearchParams();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("grant_type", "password");

    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };

    return this.http
      .post(endpoints.LOGIN_API, formData.toString(), options)
      .pipe(
        map((response: any) => {
          localStorage.setItem(
            "name",
            `${response.firstName} ${response.lastName}`
          );
          localStorage.setItem("userName", response.userName);
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
}
