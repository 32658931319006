import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";
import { throwError } from "rxjs";
import { endpoints } from "src/app/constants/endpoints";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) {}

  addUser(username, password) {
    const formData = { username: username, password: password };

    return this.http
      .post("./api/admin/user/add", formData, { responseType: "text" })
      .pipe(map((response) => response));
  }

  deactivateUser(username) {
    const formData = { username: username };
    return this.http
      .post("./api/admin/user/deactivate", formData, { responseType: "text" })
      .pipe(map((response) => response));
  }

  getAllUsers() {
    return this.http.get(`${endpoints.GET_ALL_USERS}`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getPendingAdminInvites() {
    return this.http.get(`${endpoints.GET_PENDING_ADMIN_INVITES}`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  assignAdminRole(userEmail) {
    return this.http
      .put(`${endpoints.ASSIGN_ADMIN_ROLE}?userEmail=${userEmail}`, {})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  removeAdminRole(userEmail) {
    return this.http
      .put(`${endpoints.REMOVE_ADMIN_ROLE}?userEmail=${userEmail}`, {})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  deleteUser(userEmail) {
    return this.http
      .delete(`${endpoints.DELETE_USER}?userEmail=${userEmail}`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  addAdmins(emailList) {
    return this.http.post(endpoints.ADD_ADMINS, emailList).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  fetchUsers(pageIndex?, pageSize?, sort?, filter?) {
    const formData = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sort: sort,
      filter: filter,
    };

    return this.http
      .post("./api/admin/users", formData, { responseType: "json" })
      .pipe(
        map((response: Response) => {
          //console.log(response);
          return response;
          //return response;
        })
      );
  }
}
