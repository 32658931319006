import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DigitSpanComponent } from "./components/digit-span/digit-span.component";
import { DigitSpanRunningComponent } from "./components/digit-span-running/digit-span-running.component";
import { UserManagementComponent } from "./components/admin/user-management/user-management.component";
import { AccessDeniedComponent } from "./components/access-denied/access-denied.component";
import { LocationSpanComponent } from "./components/location-span/location-span.component";
import { LocationSpanRunningComponent } from "./components/location-span-running/location-span-running.component";
import { VisualSpanComponent } from "./components/visual-span/visual-span.component";
import { VisualSpanRunningComponent } from "./components/visual-span-running/visual-span-running.component";
import { VisualBindingSpanV2Component } from "./components/visual-binding-span-v2/visual-binding-span-v2.component";
import { CrossModalBindingComponent } from "./components/cross-modal-binding/cross-modal-binding.component";
import { PhonologicalBindingComponent } from "./components/phonological-binding/phonological-binding.component";
import { NumberUpdateVisualComponent } from "./components/number-update-visual/number-update-visual.component";
import { NumberUpdateAuditoryComponent } from "./components/number-update-auditory/number-update-auditory.component";
import { NonWordComponent } from "./components/non-word/non-word.component";
import { RepetitionAuditoryComponent } from "./components/repetition-auditory/repetition-auditory.component";
import { RepetitionVisualComponent } from "./components/repetition-visual/repetition-visual.component";

import { StoreComponent } from "./components/store/store.component";

//import { HelpComponent } from "./components/help/help.component";
import { AudioRecordComponent } from "./components/audio-record/audio-record.component";
import { DataUploadRedcapComponent } from "./components/data-upload-redcap/data-upload-redcap.component";
import { ChildAuthService as childAuthService } from "./services/auth/child-auth.service";
import { LoginoauthComponent } from "./components/loginoauth/loginoauth.component";
import { ProjectsComponent } from "./components/projects/projects.component";
import { ProjectcreateComponent } from "./components/projectcreate/projectcreate.component";
import { InviteValidateComponent } from "./components/invite-validate/invite-validate.component";
import { ProjectDetailsComponent } from "./components/project-details/project-details.component";
import { ProjectManageUsers } from "./components/project-manage-users/project-manage-users.component";
import { ManageusersComponent } from "./components/manageusers/manageusers.component";
import { GamesInProjectService } from "./services/gamesinproject/games-in-project.service";
import { GameIds } from "./constants/games";
import { EditProjectComponent } from "./components/edit-project/edit-project.component";

const nonAuthUrls = [
  "noaccess",
  "admin/user",
  //"app/help",
  //"app/recording/test",
  "app/loginoauth",
  "app/inviteValidate",
];
const routes: Routes = [
  { path: "login", component: LoginComponent },
  {
    path: "app/:projectUid/dashboard",
    component: DashboardComponent,
  },
  {
    path: "app/:projectUid/digitspan",
    component: DigitSpanComponent,
    data: { gameId: GameIds.DS },
  },
  {
    path: "app/:projectUid/digitspanrunning",
    component: DigitSpanRunningComponent,
    data: { gameId: GameIds.DSR },
  },
  {
    path: "app/:projectUid/locationspan",
    component: LocationSpanComponent,
    data: { gameId: GameIds.LS },
  },
  {
    path: "app/:projectUid/locationspanrunning",
    component: LocationSpanRunningComponent,
    data: { gameId: GameIds.LSR },
  },
  {
    path: "app/:projectUid/visualspan",
    component: VisualSpanComponent,
    data: { gameId: GameIds.VS },
  },
  {
    path: "app/:projectUid/visualspanrunning",
    component: VisualSpanRunningComponent,
    data: { gameId: GameIds.VSR },
  },
  {
    path: "app/:projectUid/visualbinding",
    component: VisualBindingSpanV2Component,
    data: { gameId: GameIds.VBS },
  },
  {
    path: "app/:projectUid/crossmodalbinding",
    component: CrossModalBindingComponent,
    data: { gameId: GameIds.CMB },
  },
  {
    path: "app/:projectUid/phonological",
    component: PhonologicalBindingComponent,
    data: { gameId: GameIds.PBS },
  },
  {
    path: "app/:projectUid/numberupdatevisual",
    component: NumberUpdateVisualComponent,
    data: { gameId: GameIds.NUV },
  },
  {
    path: "app/:projectUid/numberupdateauditory",
    component: NumberUpdateAuditoryComponent,
    data: { gameId: GameIds.NUA },
  },
  {
    path: "app/:projectUid/nonword",
    component: NonWordComponent,
    data: { gameId: GameIds.NR },
  },
  {
    path: "app/:projectUid/repetitionauditory",
    component: RepetitionAuditoryComponent,
    data: { gameId: GameIds.RDA },
  },
  {
    path: "app/:projectUid/repetitionvisual",
    component: RepetitionVisualComponent,
    data: { gameId: GameIds.RDV },
  },
  { path: "noaccess", component: AccessDeniedComponent },

  {
    path: "app/:projectUid/store",
    component: StoreComponent,
  },

  { path: "admin/user", component: UserManagementComponent },

  //{ path: "app/help", component: HelpComponent },
  
  { path: "app/project/:projectUid/recordingTest", component: AudioRecordComponent },
  {
    path: "app/project/:projectUid/exportGameData",
    component: DataUploadRedcapComponent,
    canActivate: [childAuthService],
  },
  // {path: 'app/upload', component: DataUploadComponent},
  { path: "app/loginoauth", component: LoginoauthComponent },

  // route for new home page after login
  {
    path: "app/projects",
    component: ProjectsComponent,
  },
  // route for create project
  {
    path: "app/project/create",
    component: ProjectcreateComponent,
  },
  // project details page
  {
    path: "app/project/:projectUid",
    component: ProjectDetailsComponent,
  },
  // project manage users page
  {
    path: "app/project/:projectUid/manageUsers",
    component: ProjectManageUsers,
  },
  // project edit route
  {
    path: "app/project/:projectUid/editProject",
    component: EditProjectComponent,
  },
  // route for validating the invites
  { path: "app/inviteValidate", component: InviteValidateComponent },
  // page for admin to manage all the users in the app
  {
    path: "app/manageusers",
    component: ManageusersComponent,
  },
  // otherwise, redirect to homeComponent
  { path: "**", redirectTo: "app/projects" },
];

routes.forEach((_, index) => {
  if (!routes[index].redirectTo) {
    if (!routes[index].canActivate) {
      routes[index].canActivate = [];
    }

    // game route push gameInaProject guard
    if (routes[index].data && routes[index].data.gameId) {
      routes[index].canActivate.push(GamesInProjectService);
    }

    if (!nonAuthUrls.includes(routes[index].path)) {
      routes[index].canActivate.push(childAuthService);
    }
  }
});
@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {}
