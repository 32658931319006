import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";
import MicRecorder from "mic-recorder-to-mp3";
import { from } from "rxjs";
declare var p5: any;

const RECORD_BIT_RATE = 96; //Minimal recommended bit rate for MP3 for faster processing; for maximal use 320
@Injectable({
  providedIn: "root",
})
export class AudioRecordService {
  constructor(private http: HttpClient) {}

  mic;
  recorder;
  soundFile;

  soundBlob;
  mp3Recorder = new MicRecorder({
    bitRate: RECORD_BIT_RATE,
  });

  touchStarted() {
    p5.prototype.getAudioContext().resume();
  }

  startRecord() {
    this.mp3Recorder.start().catch((err) => {
      alert("The device does not support audio recording!");
    });
  }

  stopRecord() {
    return new Promise((resolve, reject) => {
      this.mp3Recorder
        .stop()
        .getMp3()
        .then(([_, blob]) => {
          resolve(blob);
        });
    });
  }

  sendRecordData(url, formData, func) {
    let response = {};
    this.http.post(url, formData).subscribe(
      (result) => (response["result"] = result), //success
      (error) => (response["error"] = error), //error
      func() //complete
    );
    return response;
  }

  sendAudioData(url, formData) {
    return this.http.post(url, formData).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  getVolume = () => {
    return this.mic?.getLevel();
  };
}
