import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { endpoints } from "src/app/constants/endpoints";
import { ChildAuthService } from "src/app/services/auth/child-auth.service";
import { GetprojectsService } from "src/app/services/getprojects/getprojects.service";

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.css"],
})
export class ProjectsComponent implements OnInit {
  isAdmin: Boolean;
  projects: any = [];
  loadingText = "";
  isDeleteModalOpen: Boolean;
  deleteProjectDetails = {};

  // get details of the logged in user.
  constructor(
    authService: ChildAuthService,
    private router: Router,
    private getProjectService: GetprojectsService,
    private spinner: NgxSpinnerService
  ) {
    this.isAdmin = authService.loggedInDetails.isAdmin;
  }

  deleteModalClose() {
    this.isDeleteModalOpen = false;
  }

  openDeleteProjectModal(projectUid, projectName) {
    this.deleteProjectDetails = {
      projectUid,
      projectName,
    };
    this.isDeleteModalOpen = true;
  }

  openProjectDetails(projectUuid) {
    this.router.navigate(["app/project", projectUuid]);
  }

  createProjectNavigate() {
    this.router.navigateByUrl("/app/project/create");
  }

  // get the logo , image path or placeholder image
  getProjectImage(logoPath) {
    if (!logoPath) {
      return "../../../assets/images/placeholder.webp";
    } else {
      return `${endpoints.GET_PROJECT_IMAGE}?imagePath=${encodeURIComponent(
        logoPath
      )}`;
    }
  }

  fetchProjects() {
    this.loadingText = "Loading Projects..";
    this.spinner.show();
    this.getProjectService.getProjects().subscribe((data) => {
      this.projects = data;
      this.spinner.hide();
    });
  }

  ngOnInit(): void {
    this.fetchProjects();
  }
}
